var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"fill-height expand-cancellation guest-page"},[_c('div',{staticClass:"guest-header"},[_c('div',{staticClass:"guest-header__inner container"},[(!_vm.getLogoUrl())?_c('div',{staticClass:"guest-header__name"},[_vm._v(" "+_vm._s(_vm.op(_vm.reservation, 'company/name'))+" ")]):_vm._e(),(_vm.getLogoUrl())?_c('div',{staticClass:"guest-header__view"},[_c('div',{staticClass:"guest-header__logo",style:(`background-image:url(${_vm.getLogoUrl()})`)})]):_vm._e()])]),(_vm.isCancellationConfirmed === false)?_c('v-form',{ref:"customer-form",attrs:{"lazy-validation":true}},[_c('v-layout',{attrs:{"row":"","wrap":"","sheet":""}},[_c('v-flex',{attrs:{"xs12":"","text-xs-center":""}},[_c('br'),_c('h1',{staticClass:"cancel-reservation-title"},[_vm._v("Cancel Reservation")])]),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('p',[_vm._v(" Please provide us with the following so that we may confirm your trip information and complete your cancellation. Card information is necessary in order to confirm your identity and issue your refund, if applicable. ")])]),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('p',[_vm._v(" Again, we want to thank you for working with "+_vm._s(_vm.op(_vm.reservation, 'companyName'))+". We hope to see you again for your future travels! ")]),_c('br'),_c('br')]),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRInput',{attrs:{"label":"Your Name","rules":_vm.confirmCancellationIndicator
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Your Name Is Required',
                    error: 'Your Name Is Required',
                  }),
                ]
              : []},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRInput',{attrs:{"label":"Relationship to Cardholder","rules":_vm.confirmCancellationIndicator
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Relationship to Cardholder Is Required',
                    error: 'Relationship to Cardholder Is Required',
                  }),
                ]
              : []},model:{value:(_vm.relationship),callback:function ($$v) {_vm.relationship=$$v},expression:"relationship"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRInput',{attrs:{"label":"Cardholder Name","rules":_vm.confirmCancellationIndicator
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Cardholder Name Is Required',
                    error: 'Cardholder Name Is Required',
                  }),
                ]
              : []},model:{value:(_vm.cardholderName),callback:function ($$v) {_vm.cardholderName=$$v},expression:"cardholderName"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRInput',{attrs:{"label":"Last four digits of credit card or checking account number","rules":_vm.confirmCancellationIndicator
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'This Information Is Required',
                    error: 'This Information Is Required',
                  }),
                  _vm.isRequired(true, _vm.isNumber, {
                    req: 'Value Must Be Valid Number',
                    error: 'Value Must Be Valid Number',
                  }),
                ]
              : [],"type":"text","maxlength":"4"},model:{value:(_vm.lastFour),callback:function ($$v) {_vm.lastFour=$$v},expression:"lastFour"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('label',[_vm._v(" Were you the person who booked the reservation and agreed to the terms on "+_vm._s(_vm.createdOnDate)+"? ")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.bookedByMe),callback:function ($$v) {_vm.bookedByMe=$$v},expression:"bookedByMe"}},[_c('v-radio',{attrs:{"value":"yes","label":"Yes"}}),_c('v-radio',{attrs:{"value":"no","label":"No"}})],1)],1),_c('v-flex',{attrs:{"xs4":""}}),(_vm.bookedByMe === 'no')?_c('v-flex',{attrs:{"xs4":""}}):_vm._e(),(_vm.bookedByMe === 'no')?_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('CRInput',{attrs:{"label":"Who booked the reservation?","rules":_vm.bookedByMe === 'no' && _vm.confirmCancellationIndicator
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'This Information Is Required',
                    error: 'This Information Is Required',
                  }),
                ]
              : []},model:{value:(_vm.whoBooked),callback:function ($$v) {_vm.whoBooked=$$v},expression:"whoBooked"}})],1):_vm._e(),(_vm.bookedByMe === 'no')?_c('v-flex',{attrs:{"xs4":""}}):_vm._e(),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('v-checkbox',{attrs:{"label":"Are you sure you want to cancel your reservation?"},model:{value:(_vm.confirmCancellationIndicator),callback:function ($$v) {_vm.confirmCancellationIndicator=$$v},expression:"confirmCancellationIndicator"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"disabled":!_vm.confirmCancellationIndicator},on:{"click":_vm.confirmCancellation}},[_vm._v(" Cancel Reservation ")])],1),_c('v-flex',{attrs:{"xs4":""}})],1)],1):_vm._e(),(_vm.isCancellationConfirmed === true)?_c('v-layout',{attrs:{"row":"","wrap":"","sheet":""}},[_c('v-flex',{attrs:{"xs12":"","text-xs-center":""}},[_c('br'),_c('h1',{staticClass:"cancel-reservation-title"},[_vm._v("Cancellation Started")]),_vm._v(" Again, we want to thank you for working with "+_vm._s(_vm.op(_vm.reservation, 'companyName'))+". We hope to see you again for your future travels! ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }